/* eslint-disable */
/* tslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveBillingRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type ApproveBillingRequestMutation = {
  approveBillingRequest: { success: boolean; description?: string | null };
};

export type DisapproveBillingRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
}>;

export type DisapproveBillingRequestMutation = {
  disapproveBillingRequest: { success: boolean; description?: string | null };
};

export type BillingRequestsListQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
  filters?: InputMaybe<BillingRequestFilter>;
  order?: InputMaybe<InvoiceOrderInput>;
}>;

export type BillingRequestsListQuery = {
  billingRequests: {
    pageInfo: { hasNextPage?: boolean | null; hasPrevPage?: boolean | null; totalCount?: number | null };
    billingRequests: Array<{
      uuid: string;
      amount?: string | null;
      amountWithVat?: string | null;
      created?: string | null;
      updated?: string | null;
      invoicedOn?: string | null;
      status?: BillingRequestStatusEnum | null;
      disapprovalReason?: string | null;
      enableDirectPayment?: boolean | null;
      vatExempt?: boolean | null;
      hoursLineItems?: Array<{ uuid: string; breakItems: Array<{ uuid: string }> }> | null;
      unitLineItems?: Array<{ uuid: string }> | null;
      expenseLineItems?: Array<{ uuid: string }> | null;
      relations?: {
        issuedToBranchId?: string | null;
        issuedToCompanyId?: string | null;
        issuedToDepartmentId?: string | null;
        issuedViaCompanyId?: string | null;
        issuerCompanyId?: string | null;
        projectId?: string | null;
        branchName?: string | null;
        departmentName?: string | null;
        issuedToName?: string | null;
        issuedViaName?: string | null;
        issuerName?: string | null;
        projectName?: string | null;
        projectPoNumber?: string | null;
      } | null;
    }>;
  };
};

export type CollectiveInvoiceQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type CollectiveInvoiceQuery = {
  collectiveInvoice: {
    amount: string;
    amountWithVat: string;
    currency: InvoiceCurrency;
    created: string;
    daysOutstanding?: number | null;
    dueDate?: string | null;
    identifier?: string | null;
    invoiceType?: InvoiceTypesEnum | null;
    isPaymentAvailable?: boolean | null;
    issueDate?: string | null;
    outstandingTotal: string;
    paidDate?: string | null;
    paymentStatus?: PaymentStatusEnum | null;
    paymentTerm?: number | null;
    pdf?: string | null;
    product?: InvoiceProductEnum | null;
    receivableTotal: string;
    ublAttachments: Array<string>;
    uuid: string;
    week?: number | null;
    year?: number | null;
    status?: InvoiceStatusEnum | null;
    relations: { projectId?: string | null; projectName?: string | null; projectPoNumber?: string | null };
    subInvoice: Array<{
      amount: string;
      amountWithVat: string;
      created: string;
      currency: InvoiceCurrency;
      identifier: string;
      invoiceType?: string | null;
      issueDate?: string | null;
      paidDate?: string | null;
      pdf?: string | null;
      product?: InvoiceProductEnum | null;
      status: SubInvoiceStatusEnum;
      uuid: string;
      attachments: Array<string>;
      relations: {
        branchName?: string | null;
        issuerCompanyId?: string | null;
        issuerDirectorFirstName?: string | null;
        issuerDirectorLastName?: string | null;
        issuerName?: string | null;
        projectId?: string | null;
        projectName?: string | null;
        projectPoNumber?: string | null;
        issuedToCompanyId?: string | null;
        issuedToName?: string | null;
      };
    }>;
  };
};

export type CollectiveInvoiceListQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
  filters?: InputMaybe<InvoicesFilterInput>;
  order?: InputMaybe<InvoiceOrderInput>;
}>;

export type CollectiveInvoiceListQuery = {
  collectiveInvoiceList: {
    pageInfo: { hasNextPage?: boolean | null; hasPrevPage?: boolean | null; totalCount?: number | null };
    collectiveInvoices: Array<{
      id: string;
      identifier?: string | null;
      issueDate?: string | null;
      dueDate?: string | null;
      amountWithVat: string;
      amount: string;
      outstandingTotal: string;
      status?: InvoiceStatusEnum | null;
      paymentStatus?: PaymentStatusEnum | null;
      paidDate?: string | null;
      paymentTerm?: number | null;
      daysOutstanding?: number | null;
      currency: InvoiceCurrency;
      receivableTotal: string;
      isCollective?: boolean | null;
      pdf?: string | null;
      supplier?: { companyId: string; name: string } | null;
    }>;
  };
};

export type AccountInfoQueryVariables = Exact<{ [key: string]: never }>;

export type AccountInfoQuery = {
  accountInfo: { officialName: string; debtorId: string; contactInfo?: { name: string } | null };
};

export type CreateDirectDebitAuthorizationMutationVariables = Exact<{
  payload: DirectDebitInputType;
}>;

export type CreateDirectDebitAuthorizationMutation = { createDirectDebitAuthorization: { uuid: string } };

export type DirectDebitQueryVariables = Exact<{ [key: string]: never }>;

export type DirectDebitQuery = {
  accountInfo: {
    directDebit?: {
      id: string;
      directDebitMandateId?: string | null;
      directDebitLimit?: string | null;
      directDebitExternalId: string;
      directDebitExpiration?: string | null;
      directDebitSignatureDate?: string | null;
    } | null;
  };
};

export type PayInvoicesMutationVariables = Exact<{
  payload: PayInvoicesInput;
}>;

export type PayInvoicesMutation = { payInvoices: { url: string } };

export type InvoiceListQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
  filters?: InputMaybe<InvoicesFilterInput>;
  order?: InputMaybe<InvoiceOrderInput>;
}>;

export type InvoiceListQuery = {
  invoiceList: {
    pageInfo: { hasNextPage?: boolean | null; hasPrevPage?: boolean | null; totalCount?: number | null };
    invoices: Array<{
      id: string;
      identifier?: string | null;
      issueDate?: string | null;
      dueDate?: string | null;
      amountWithVat: string;
      amount: string;
      outstandingTotal: string;
      status?: InvoiceStatusEnum | null;
      paymentStatus?: PaymentStatusEnum | null;
      paidDate?: string | null;
      paymentTerm?: number | null;
      daysOutstanding?: number | null;
      currency: InvoiceCurrency;
      receivableTotal: string;
      isCollective?: boolean | null;
      pdf?: string | null;
      supplier?: { companyId: string; name: string } | null;
    }>;
  };
};

export type InvoiceQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type InvoiceQuery = {
  invoice: {
    issueDate?: string | null;
    identifier: string;
    uuid: string;
    amount: string;
    amountWithVat: string;
    receivableTotal: string;
    outstandingTotal: string;
    pdf?: string | null;
    paidDate?: string | null;
    paymentTerm: number;
    paymentStatus?: PaymentStatusEnum | null;
    updated?: string | null;
    created: string;
    currency: InvoiceCurrency;
    attachments: Array<string>;
    daysOutstanding?: number | null;
    dueDate?: string | null;
    product?: InvoiceProductEnum | null;
    isPaymentAvailable?: boolean | null;
    week: number;
    year: number;
    status: InvoiceStatusEnum;
    relations: {
      projectId?: string | null;
      projectName?: string | null;
      projectPoNumber?: string | null;
      issuedToName?: string | null;
      issuedToCompanyId?: string | null;
      issuerCompanyId?: string | null;
      issuerName?: string | null;
      branchName?: string | null;
      issuerDirectorFirstName?: string | null;
      issuerDirectorLastName?: string | null;
    };
  };
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = {
  accountInfo: {
    created: string;
    officialName: string;
    registrationNumber: string;
    vatNumber: string;
    legalForm: string;
    branchNumber?: string | null;
    directPayCheckoutEnabled?: boolean | null;
    contactInfo?: { name: string; phone: string; mobile: string; website: string } | null;
    director?: { salutation: string; initials: string; firstName: string; lastName: string } | null;
    billingInfo?: {
      accountNumber: string;
      accountName: string;
      bic?: string | null;
      invoiceEmail?: string | null;
      accountsPayableEmail?: string | null;
      paymentTerm?: number | null;
      address?: { street: string; houseNumber: string; postalCode: string; city: string; countryCode: string } | null;
    } | null;
    directDebit?: {
      id: string;
      directDebitMandateId?: string | null;
      directDebitLimit?: string | null;
      directDebitExternalId: string;
      directDebitExpiration?: string | null;
      directDebitSignatureDate?: string | null;
    } | null;
  };
};

export type AgreementsQueryVariables = Exact<{ [key: string]: never }>;

export type AgreementsQuery = {
  agreements: Array<{
    status?: ContractStatusEnum | null;
    pdfUrl?: string | null;
    startDate?: string | null;
    endDate?: string | null;
  }>;
};

export type SuppliersFilterSuggestionQueryVariables = Exact<{ [key: string]: never }>;

export type SuppliersFilterSuggestionQuery = { suppliersFilterSuggestion: Array<{ companyId: string; name: string }> };

export type SuppliersQueryVariables = Exact<{
  filters?: InputMaybe<SupplierTypeFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
}>;

export type SuppliersQuery = {
  suppliers: {
    pageInfo: { hasNextPage?: boolean | null; hasPrevPage?: boolean | null; totalCount?: number | null };
    items: Array<{
      companyId: string;
      companyName: string;
      contactEmail?: string | null;
      contactName?: string | null;
      directDebitEnabled: boolean;
      merchantId: string;
      regNumber: string;
      vatNumber: string;
    }>;
  };
};

export type SupplierQueryVariables = Exact<{
  merchantId: Scalars['UUID']['input'];
}>;

export type SupplierQuery = {
  supplier: {
    merchantId: string;
    bankDetails: { accountCode: string; accountName: string; accountNumber: string };
    billingDetails: {
      accountsPayableEmails: Array<string>;
      invoicingEmails: Array<string>;
      paymentTerm?: number | null;
    };
    company: {
      address: string;
      branchNumber: string;
      directDebitEnabled: boolean;
      id: string;
      regNumber: string;
      vatNumber: string;
      officialName: string;
      logo?: string | null;
    };
    contactDetails: {
      salutation: string;
      phone: string;
      mobile: string;
      lastName: string;
      initials: string;
      firstName: string;
    };
  };
};

export const ApproveBillingRequestDocument = gql`
  mutation ApproveBillingRequest($uuid: UUID!) {
    approveBillingRequest(uuid: $uuid) {
      success
      description
    }
  }
`;
export type ApproveBillingRequestMutationFn = Apollo.MutationFunction<
  ApproveBillingRequestMutation,
  ApproveBillingRequestMutationVariables
>;

/**
 * __useApproveBillingRequestMutation__
 *
 * To run a mutation, you first call `useApproveBillingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveBillingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveBillingRequestMutation, { data, loading, error }] = useApproveBillingRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useApproveBillingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<ApproveBillingRequestMutation, ApproveBillingRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveBillingRequestMutation, ApproveBillingRequestMutationVariables>(
    ApproveBillingRequestDocument,
    options,
  );
}
export type ApproveBillingRequestMutationHookResult = ReturnType<typeof useApproveBillingRequestMutation>;
export type ApproveBillingRequestMutationResult = Apollo.MutationResult<ApproveBillingRequestMutation>;
export type ApproveBillingRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveBillingRequestMutation,
  ApproveBillingRequestMutationVariables
>;
export const DisapproveBillingRequestDocument = gql`
  mutation DisapproveBillingRequest($uuid: UUID!, $reason: String!) {
    disapproveBillingRequest(uuid: $uuid, reason: $reason) {
      success
      description
    }
  }
`;
export type DisapproveBillingRequestMutationFn = Apollo.MutationFunction<
  DisapproveBillingRequestMutation,
  DisapproveBillingRequestMutationVariables
>;

/**
 * __useDisapproveBillingRequestMutation__
 *
 * To run a mutation, you first call `useDisapproveBillingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisapproveBillingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disapproveBillingRequestMutation, { data, loading, error }] = useDisapproveBillingRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDisapproveBillingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<DisapproveBillingRequestMutation, DisapproveBillingRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisapproveBillingRequestMutation, DisapproveBillingRequestMutationVariables>(
    DisapproveBillingRequestDocument,
    options,
  );
}
export type DisapproveBillingRequestMutationHookResult = ReturnType<typeof useDisapproveBillingRequestMutation>;
export type DisapproveBillingRequestMutationResult = Apollo.MutationResult<DisapproveBillingRequestMutation>;
export type DisapproveBillingRequestMutationOptions = Apollo.BaseMutationOptions<
  DisapproveBillingRequestMutation,
  DisapproveBillingRequestMutationVariables
>;
export const BillingRequestsListDocument = gql`
  query BillingRequestsList($pagination: PaginationInput, $filters: BillingRequestFilter, $order: InvoiceOrderInput) {
    billingRequests(pagination: $pagination, filters: $filters, order: $order) {
      pageInfo {
        hasNextPage
        hasPrevPage
        totalCount
      }
      billingRequests {
        uuid
        amount
        amountWithVat
        created
        updated
        invoicedOn
        status
        disapprovalReason
        enableDirectPayment
        vatExempt
        hoursLineItems {
          uuid
          breakItems {
            uuid
          }
        }
        unitLineItems {
          uuid
        }
        expenseLineItems {
          uuid
        }
        relations {
          issuedToBranchId
          issuedToCompanyId
          issuedToDepartmentId
          issuedViaCompanyId
          issuerCompanyId
          projectId
          branchName
          departmentName
          issuedToName
          issuedViaName
          issuerName
          projectName
          projectPoNumber
        }
      }
    }
  }
`;

/**
 * __useBillingRequestsListQuery__
 *
 * To run a query within a React component, call `useBillingRequestsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingRequestsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingRequestsListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBillingRequestsListQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingRequestsListQuery, BillingRequestsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingRequestsListQuery, BillingRequestsListQueryVariables>(
    BillingRequestsListDocument,
    options,
  );
}
export function useBillingRequestsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingRequestsListQuery, BillingRequestsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingRequestsListQuery, BillingRequestsListQueryVariables>(
    BillingRequestsListDocument,
    options,
  );
}
export function useBillingRequestsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BillingRequestsListQuery, BillingRequestsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BillingRequestsListQuery, BillingRequestsListQueryVariables>(
    BillingRequestsListDocument,
    options,
  );
}
export type BillingRequestsListQueryHookResult = ReturnType<typeof useBillingRequestsListQuery>;
export type BillingRequestsListLazyQueryHookResult = ReturnType<typeof useBillingRequestsListLazyQuery>;
export type BillingRequestsListSuspenseQueryHookResult = ReturnType<typeof useBillingRequestsListSuspenseQuery>;
export type BillingRequestsListQueryResult = Apollo.QueryResult<
  BillingRequestsListQuery,
  BillingRequestsListQueryVariables
>;
export const CollectiveInvoiceDocument = gql`
  query CollectiveInvoice($uuid: UUID!) {
    collectiveInvoice(uuid: $uuid) {
      amount
      amountWithVat
      currency
      created
      daysOutstanding
      dueDate
      identifier
      invoiceType
      isPaymentAvailable
      issueDate
      outstandingTotal
      paidDate
      paymentStatus
      paymentTerm
      pdf
      product
      receivableTotal
      ublAttachments
      uuid
      week
      year
      status
      relations {
        projectId
        projectName
        projectPoNumber
      }
      subInvoice {
        amount
        amountWithVat
        created
        currency
        identifier
        invoiceType
        issueDate
        paidDate
        pdf
        product
        status
        relations {
          branchName
          issuerCompanyId
          issuerDirectorFirstName
          issuerDirectorLastName
          issuerName
          projectId
          projectName
          projectPoNumber
          issuedToCompanyId
          issuedToName
        }
        uuid
        attachments
      }
    }
  }
`;

/**
 * __useCollectiveInvoiceQuery__
 *
 * To run a query within a React component, call `useCollectiveInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveInvoiceQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCollectiveInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<CollectiveInvoiceQuery, CollectiveInvoiceQueryVariables> &
    ({ variables: CollectiveInvoiceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollectiveInvoiceQuery, CollectiveInvoiceQueryVariables>(CollectiveInvoiceDocument, options);
}
export function useCollectiveInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectiveInvoiceQuery, CollectiveInvoiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollectiveInvoiceQuery, CollectiveInvoiceQueryVariables>(
    CollectiveInvoiceDocument,
    options,
  );
}
export function useCollectiveInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CollectiveInvoiceQuery, CollectiveInvoiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CollectiveInvoiceQuery, CollectiveInvoiceQueryVariables>(
    CollectiveInvoiceDocument,
    options,
  );
}
export type CollectiveInvoiceQueryHookResult = ReturnType<typeof useCollectiveInvoiceQuery>;
export type CollectiveInvoiceLazyQueryHookResult = ReturnType<typeof useCollectiveInvoiceLazyQuery>;
export type CollectiveInvoiceSuspenseQueryHookResult = ReturnType<typeof useCollectiveInvoiceSuspenseQuery>;
export type CollectiveInvoiceQueryResult = Apollo.QueryResult<CollectiveInvoiceQuery, CollectiveInvoiceQueryVariables>;
export const CollectiveInvoiceListDocument = gql`
  query CollectiveInvoiceList($pagination: PaginationInput, $filters: InvoicesFilterInput, $order: InvoiceOrderInput) {
    collectiveInvoiceList(pagination: $pagination, filters: $filters, order: $order) {
      pageInfo {
        hasNextPage
        hasPrevPage
        totalCount
      }
      collectiveInvoices {
        id
        identifier
        issueDate
        dueDate
        amountWithVat
        amount
        outstandingTotal
        status
        paymentStatus
        paidDate
        paymentTerm
        daysOutstanding
        currency
        receivableTotal
        isCollective
        supplier {
          companyId
          name
        }
        pdf
      }
    }
  }
`;

/**
 * __useCollectiveInvoiceListQuery__
 *
 * To run a query within a React component, call `useCollectiveInvoiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveInvoiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveInvoiceListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCollectiveInvoiceListQuery(
  baseOptions?: Apollo.QueryHookOptions<CollectiveInvoiceListQuery, CollectiveInvoiceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollectiveInvoiceListQuery, CollectiveInvoiceListQueryVariables>(
    CollectiveInvoiceListDocument,
    options,
  );
}
export function useCollectiveInvoiceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectiveInvoiceListQuery, CollectiveInvoiceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollectiveInvoiceListQuery, CollectiveInvoiceListQueryVariables>(
    CollectiveInvoiceListDocument,
    options,
  );
}
export function useCollectiveInvoiceListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CollectiveInvoiceListQuery, CollectiveInvoiceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CollectiveInvoiceListQuery, CollectiveInvoiceListQueryVariables>(
    CollectiveInvoiceListDocument,
    options,
  );
}
export type CollectiveInvoiceListQueryHookResult = ReturnType<typeof useCollectiveInvoiceListQuery>;
export type CollectiveInvoiceListLazyQueryHookResult = ReturnType<typeof useCollectiveInvoiceListLazyQuery>;
export type CollectiveInvoiceListSuspenseQueryHookResult = ReturnType<typeof useCollectiveInvoiceListSuspenseQuery>;
export type CollectiveInvoiceListQueryResult = Apollo.QueryResult<
  CollectiveInvoiceListQuery,
  CollectiveInvoiceListQueryVariables
>;
export const AccountInfoDocument = gql`
  query AccountInfo {
    accountInfo {
      officialName
      debtorId
      contactInfo {
        name
      }
    }
  }
`;

/**
 * __useAccountInfoQuery__
 *
 * To run a query within a React component, call `useAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountInfoQuery, AccountInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountInfoQuery, AccountInfoQueryVariables>(AccountInfoDocument, options);
}
export function useAccountInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountInfoQuery, AccountInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountInfoQuery, AccountInfoQueryVariables>(AccountInfoDocument, options);
}
export function useAccountInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AccountInfoQuery, AccountInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountInfoQuery, AccountInfoQueryVariables>(AccountInfoDocument, options);
}
export type AccountInfoQueryHookResult = ReturnType<typeof useAccountInfoQuery>;
export type AccountInfoLazyQueryHookResult = ReturnType<typeof useAccountInfoLazyQuery>;
export type AccountInfoSuspenseQueryHookResult = ReturnType<typeof useAccountInfoSuspenseQuery>;
export type AccountInfoQueryResult = Apollo.QueryResult<AccountInfoQuery, AccountInfoQueryVariables>;
export const CreateDirectDebitAuthorizationDocument = gql`
  mutation CreateDirectDebitAuthorization($payload: DirectDebitInputType!) {
    createDirectDebitAuthorization(payload: $payload) {
      uuid
    }
  }
`;
export type CreateDirectDebitAuthorizationMutationFn = Apollo.MutationFunction<
  CreateDirectDebitAuthorizationMutation,
  CreateDirectDebitAuthorizationMutationVariables
>;

/**
 * __useCreateDirectDebitAuthorizationMutation__
 *
 * To run a mutation, you first call `useCreateDirectDebitAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectDebitAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectDebitAuthorizationMutation, { data, loading, error }] = useCreateDirectDebitAuthorizationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateDirectDebitAuthorizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDirectDebitAuthorizationMutation,
    CreateDirectDebitAuthorizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDirectDebitAuthorizationMutation, CreateDirectDebitAuthorizationMutationVariables>(
    CreateDirectDebitAuthorizationDocument,
    options,
  );
}
export type CreateDirectDebitAuthorizationMutationHookResult = ReturnType<
  typeof useCreateDirectDebitAuthorizationMutation
>;
export type CreateDirectDebitAuthorizationMutationResult =
  Apollo.MutationResult<CreateDirectDebitAuthorizationMutation>;
export type CreateDirectDebitAuthorizationMutationOptions = Apollo.BaseMutationOptions<
  CreateDirectDebitAuthorizationMutation,
  CreateDirectDebitAuthorizationMutationVariables
>;
export const DirectDebitDocument = gql`
  query DirectDebit {
    accountInfo {
      directDebit {
        id
        directDebitMandateId
        directDebitLimit
        directDebitExternalId
        directDebitExpiration
        directDebitSignatureDate
      }
    }
  }
`;

/**
 * __useDirectDebitQuery__
 *
 * To run a query within a React component, call `useDirectDebitQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectDebitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectDebitQuery({
 *   variables: {
 *   },
 * });
 */
export function useDirectDebitQuery(
  baseOptions?: Apollo.QueryHookOptions<DirectDebitQuery, DirectDebitQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DirectDebitQuery, DirectDebitQueryVariables>(DirectDebitDocument, options);
}
export function useDirectDebitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DirectDebitQuery, DirectDebitQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DirectDebitQuery, DirectDebitQueryVariables>(DirectDebitDocument, options);
}
export function useDirectDebitSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DirectDebitQuery, DirectDebitQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DirectDebitQuery, DirectDebitQueryVariables>(DirectDebitDocument, options);
}
export type DirectDebitQueryHookResult = ReturnType<typeof useDirectDebitQuery>;
export type DirectDebitLazyQueryHookResult = ReturnType<typeof useDirectDebitLazyQuery>;
export type DirectDebitSuspenseQueryHookResult = ReturnType<typeof useDirectDebitSuspenseQuery>;
export type DirectDebitQueryResult = Apollo.QueryResult<DirectDebitQuery, DirectDebitQueryVariables>;
export const PayInvoicesDocument = gql`
  mutation PayInvoices($payload: PayInvoicesInput!) {
    payInvoices(payload: $payload) {
      url
    }
  }
`;
export type PayInvoicesMutationFn = Apollo.MutationFunction<PayInvoicesMutation, PayInvoicesMutationVariables>;

/**
 * __usePayInvoicesMutation__
 *
 * To run a mutation, you first call `usePayInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoicesMutation, { data, loading, error }] = usePayInvoicesMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePayInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<PayInvoicesMutation, PayInvoicesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayInvoicesMutation, PayInvoicesMutationVariables>(PayInvoicesDocument, options);
}
export type PayInvoicesMutationHookResult = ReturnType<typeof usePayInvoicesMutation>;
export type PayInvoicesMutationResult = Apollo.MutationResult<PayInvoicesMutation>;
export type PayInvoicesMutationOptions = Apollo.BaseMutationOptions<PayInvoicesMutation, PayInvoicesMutationVariables>;
export const InvoiceListDocument = gql`
  query InvoiceList($pagination: PaginationInput, $filters: InvoicesFilterInput, $order: InvoiceOrderInput) {
    invoiceList(pagination: $pagination, filters: $filters, order: $order) {
      pageInfo {
        hasNextPage
        hasPrevPage
        totalCount
      }
      invoices {
        id
        identifier
        issueDate
        dueDate
        amountWithVat
        amount
        outstandingTotal
        status
        paymentStatus
        paidDate
        paymentTerm
        daysOutstanding
        currency
        receivableTotal
        isCollective
        supplier {
          companyId
          name
        }
        pdf
      }
    }
  }
`;

/**
 * __useInvoiceListQuery__
 *
 * To run a query within a React component, call `useInvoiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceListQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoiceListQuery, InvoiceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceListQuery, InvoiceListQueryVariables>(InvoiceListDocument, options);
}
export function useInvoiceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoiceListQuery, InvoiceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceListQuery, InvoiceListQueryVariables>(InvoiceListDocument, options);
}
export function useInvoiceListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvoiceListQuery, InvoiceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoiceListQuery, InvoiceListQueryVariables>(InvoiceListDocument, options);
}
export type InvoiceListQueryHookResult = ReturnType<typeof useInvoiceListQuery>;
export type InvoiceListLazyQueryHookResult = ReturnType<typeof useInvoiceListLazyQuery>;
export type InvoiceListSuspenseQueryHookResult = ReturnType<typeof useInvoiceListSuspenseQuery>;
export type InvoiceListQueryResult = Apollo.QueryResult<InvoiceListQuery, InvoiceListQueryVariables>;
export const InvoiceDocument = gql`
  query Invoice($uuid: UUID!) {
    invoice(uuid: $uuid) {
      issueDate
      identifier
      uuid
      amount
      amountWithVat
      receivableTotal
      outstandingTotal
      pdf
      paidDate
      paymentTerm
      paymentStatus
      updated
      created
      currency
      attachments
      daysOutstanding
      dueDate
      product
      isPaymentAvailable
      week
      year
      status
      relations {
        projectId
        projectName
        projectPoNumber
        issuedToName
        issuedToCompanyId
        issuerCompanyId
        issuerName
        branchName
        issuerDirectorFirstName
        issuerDirectorLastName
      }
    }
  }
`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables> &
    ({ variables: InvoiceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
}
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
}
export function useInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceSuspenseQueryHookResult = ReturnType<typeof useInvoiceSuspenseQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const ProfileDocument = gql`
  query Profile {
    accountInfo {
      created
      officialName
      registrationNumber
      vatNumber
      legalForm
      branchNumber
      directPayCheckoutEnabled
      contactInfo {
        name
        phone
        mobile
        website
      }
      director {
        salutation
        initials
        firstName
        lastName
      }
      billingInfo {
        accountNumber
        accountName
        bic
        invoiceEmail
        accountsPayableEmail
        paymentTerm
        address {
          street
          houseNumber
          postalCode
          city
          countryCode
        }
      }
      directDebit {
        id
        directDebitMandateId
        directDebitLimit
        directDebitExternalId
        directDebitExpiration
        directDebitSignatureDate
      }
    }
  }
`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
}
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
}
export function useProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProfileQuery, ProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileSuspenseQueryHookResult = ReturnType<typeof useProfileSuspenseQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const AgreementsDocument = gql`
  query Agreements {
    agreements {
      status
      pdfUrl
      startDate
      endDate
    }
  }
`;

/**
 * __useAgreementsQuery__
 *
 * To run a query within a React component, call `useAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgreementsQuery(baseOptions?: Apollo.QueryHookOptions<AgreementsQuery, AgreementsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options);
}
export function useAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgreementsQuery, AgreementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options);
}
export function useAgreementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AgreementsQuery, AgreementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options);
}
export type AgreementsQueryHookResult = ReturnType<typeof useAgreementsQuery>;
export type AgreementsLazyQueryHookResult = ReturnType<typeof useAgreementsLazyQuery>;
export type AgreementsSuspenseQueryHookResult = ReturnType<typeof useAgreementsSuspenseQuery>;
export type AgreementsQueryResult = Apollo.QueryResult<AgreementsQuery, AgreementsQueryVariables>;
export const SuppliersFilterSuggestionDocument = gql`
  query SuppliersFilterSuggestion {
    suppliersFilterSuggestion {
      companyId
      name
    }
  }
`;

/**
 * __useSuppliersFilterSuggestionQuery__
 *
 * To run a query within a React component, call `useSuppliersFilterSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersFilterSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersFilterSuggestionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersFilterSuggestionQuery(
  baseOptions?: Apollo.QueryHookOptions<SuppliersFilterSuggestionQuery, SuppliersFilterSuggestionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuppliersFilterSuggestionQuery, SuppliersFilterSuggestionQueryVariables>(
    SuppliersFilterSuggestionDocument,
    options,
  );
}
export function useSuppliersFilterSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuppliersFilterSuggestionQuery, SuppliersFilterSuggestionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuppliersFilterSuggestionQuery, SuppliersFilterSuggestionQueryVariables>(
    SuppliersFilterSuggestionDocument,
    options,
  );
}
export function useSuppliersFilterSuggestionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SuppliersFilterSuggestionQuery,
    SuppliersFilterSuggestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SuppliersFilterSuggestionQuery, SuppliersFilterSuggestionQueryVariables>(
    SuppliersFilterSuggestionDocument,
    options,
  );
}
export type SuppliersFilterSuggestionQueryHookResult = ReturnType<typeof useSuppliersFilterSuggestionQuery>;
export type SuppliersFilterSuggestionLazyQueryHookResult = ReturnType<typeof useSuppliersFilterSuggestionLazyQuery>;
export type SuppliersFilterSuggestionSuspenseQueryHookResult = ReturnType<
  typeof useSuppliersFilterSuggestionSuspenseQuery
>;
export type SuppliersFilterSuggestionQueryResult = Apollo.QueryResult<
  SuppliersFilterSuggestionQuery,
  SuppliersFilterSuggestionQueryVariables
>;
export const SuppliersDocument = gql`
  query Suppliers($filters: SupplierTypeFilter, $pagination: OffsetPaginationInput) {
    suppliers(filters: $filters, pagination: $pagination) {
      pageInfo {
        hasNextPage
        hasPrevPage
        totalCount
      }
      items {
        companyId
        companyName
        contactEmail
        contactName
        directDebitEnabled
        merchantId
        regNumber
        vatNumber
      }
    }
  }
`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
}
export function useSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
}
export function useSuppliersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersSuspenseQueryHookResult = ReturnType<typeof useSuppliersSuspenseQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const SupplierDocument = gql`
  query Supplier($merchantId: UUID!) {
    supplier(merchantId: $merchantId) {
      bankDetails {
        accountCode
        accountName
        accountNumber
      }
      billingDetails {
        accountsPayableEmails
        invoicingEmails
        paymentTerm
      }
      company {
        address
        branchNumber
        directDebitEnabled
        id
        regNumber
        vatNumber
        officialName
        logo
      }
      contactDetails {
        salutation
        phone
        mobile
        lastName
        initials
        firstName
      }
      merchantId
    }
  }
`;

/**
 * __useSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useSupplierQuery(
  baseOptions: Apollo.QueryHookOptions<SupplierQuery, SupplierQueryVariables> &
    ({ variables: SupplierQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
}
export function useSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierQuery, SupplierQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
}
export function useSupplierSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SupplierQuery, SupplierQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
}
export type SupplierQueryHookResult = ReturnType<typeof useSupplierQuery>;
export type SupplierLazyQueryHookResult = ReturnType<typeof useSupplierLazyQuery>;
export type SupplierSuspenseQueryHookResult = ReturnType<typeof useSupplierSuspenseQuery>;
export type SupplierQueryResult = Apollo.QueryResult<SupplierQuery, SupplierQueryVariables>;
